.calculator {
  padding: 20px;
  height: 700px;
  width: 400px;
  overflow: hidden;
  border-radius: 20px;
  background-color: var(--calc-bg);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.calculator__btns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.calculator__btns button {
  border: 0;
  outline: 0;
  border-radius: 10px;
  font-size: 1.25rem;
  font-weight: 500;
  background-color: transparent;
  color: var(--txt-color);
}

.calculator__btns button:last-child {
  border-radius: 50%;
  color: var(--txt-white);
  background-image: linear-gradient(312deg, #cd6e36, #d400ff);
}

.calculator__btns .btn__op {
  color: var(--calc-btn-color);
}

.calculator__result {
  position: relative;
  margin-bottom: 20px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.calculator__result__exp {
  font-size: 3.5rem;
  line-height: 1;
  transform-origin: right bottom;
  transition: transform 0.3s ease;
  color: var(--calc-res-color);
}

.calculator__result__exp:last-child {
  position: absolute;
  right: 0;
}

.calculator__result__exp span {
  display: inline-block;
  overflow: hidden;
  transition: width 0.3s ease;
}

@media only screen and (max-width: 800px) {
  .calculator {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
}
