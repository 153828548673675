@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);
:root {
  --body-bg: #f5f5f5;
  --txt-color: #6d6d6d;
  --txt-white: #fff;
  --calc-res-color: #2f2d2f;
  --calc-btn-color: #d400ff;
  --calc-bg: #fff;
}

.dark {
  --body-bg: #373737;
  --calc-res-color: #fff;
  --calc-bg: #373737;
  --txt-color: #fff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;

  background-color: #f5f5f5;

  background-color: var(--body-bg);
  color: #6d6d6d;
  color: var(--txt-color);

  display: flex;
  justify-content: center;
  padding-top: 100px;
}

button {
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  body {
    display: unset;
    padding-top: 0;
  }
}

.calculator {
  padding: 20px;
  height: 700px;
  width: 400px;
  overflow: hidden;
  border-radius: 20px;
  background-color: var(--calc-bg);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.calculator__btns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  gap: 10px;
}

.calculator__btns button {
  border: 0;
  outline: 0;
  border-radius: 10px;
  font-size: 1.25rem;
  font-weight: 500;
  background-color: transparent;
  color: var(--txt-color);
}

.calculator__btns button:last-child {
  border-radius: 50%;
  color: var(--txt-white);
  background-image: linear-gradient(312deg, #cd6e36, #d400ff);
}

.calculator__btns .btn__op {
  color: var(--calc-btn-color);
}

.calculator__result {
  position: relative;
  margin-bottom: 20px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.calculator__result__exp {
  font-size: 3.5rem;
  line-height: 1;
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  color: var(--calc-res-color);
}

.calculator__result__exp:last-child {
  position: absolute;
  right: 0;
}

.calculator__result__exp span {
  display: inline-block;
  overflow: hidden;
  transition: width 0.3s ease;
}

@media only screen and (max-width: 800px) {
  .calculator {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
}

